<template>
    <div>
        <section class="hero is-primary">
            <div class="hero-body">
                <p class="title">
                    Roles Settings
                </p>
                <p class="subtitle">
                    for <strong>{{ guild.name }}</strong>
                </p>
            </div>
        </section>
        <div class="container mt-3">
          <div v-if="hasPermission('guild.update')">
            <b-tabs v-model="activeTab" vertical :animated="false" class="sd">
                <b-tab-item v-for="(role, i) in roles" v-bind:key="i" :label="role.name" class="posfixed">
                  <b-field label="Enabled">
                    <b-switch v-model="form[i].enabled" type="is-success"/>
                  </b-field>

                  <div class="columns">
                    <div class="column">
                      <b-field label="Level">
                          <b-numberinput :disabled="!form[i].enabled" v-model="form[i].level" step="1" min="0" block controls-position="compact" controls-alignment="right"></b-numberinput>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Daily Tickets">
                          <b-numberinput :disabled="!form[i].enabled" v-model="form[i].dailyTickets" step="1" min="0" block controls-position="compact" controls-alignment="right"></b-numberinput>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field label="is Color">
                        <b-switch :disabled="!form[i].enabled" v-model="form[i].isColor" type="is-success"/>
                      </b-field>
                    </div>
                  </div>
                </b-tab-item>
            </b-tabs>
            <div class="columns has-text-right">
                <div class="column buttons">
                    <b-button
                        label="Reset"
                        type="is-primary"
                        @click="reset" />
                    <b-button
                        label="Save"
                        type="is-info"
                        @click="save" />
                </div>
            </div>
          </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.posfixed {
  position: fixed;
}
</style>

<script>
import { hasPermission } from '@/utils/permissions'
import { getGuild, updateRoles } from '@/api/guild'
export default {
  data: () => ({
    activeTab: '',
    madeChanges: false,
    guild: {},
    roles: [],
    form: []
  }),
  methods: {
    hasPermission (permission) {
      return hasPermission(permission)
    },
    reset () {
      this.form = this.roles.map(r => r.settings)
    },
    async save () {
      try {
        await updateRoles(this.form)
        this.$buefy.notification.open({
          message: 'Roles updated successfully!',
          type: 'is-success'
        })
      } catch (err) {
        this.$buefy.notification.open({
          message: err.message,
          type: 'is-danger'
        })
      }
    },
    async fetchData () {
      this.guild = await getGuild()

      this.roles = Object.values(this.guild.roles)
        .sort((a, b) => b.rawPosition - a.rawPosition)
        .map(r => ({ ...r }))

      this.reset()
    }
  },
  mounted () {
    const load = this.$buefy.loading.open()
    this.fetchData().then(() => load.close())
  }
}
</script>
